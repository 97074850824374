
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../components/Empty.vue";
import { Analytics } from "../../common/analytics";
/**
 * services
 */
import services from "../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Empty,
    IonSpinner,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonImg,
  },
  setup() {
    const router = useRouter();
    const screenName = "PAGE-EVENT_PROGRESS";
    const screenNameClose = "PAGE-EVENT_CLOSE";
    const analytics = new Analytics();
    const state = reactive({
      event: {
        name: "event",
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      activetab: "progress",
      contentLoading: true,
      isScrollDisabled: false,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      const params = {
        size: state.event.size,
        page: state.event.page,
      };

      if (state.activetab == "progress") {
        await services.eventFindAll(params).then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }

            data.items.some((item: object) => {
              state.event.items.push(item);
            });

            state.event.totalItems = data.totalItems;
            state.event.totalPages = data.totalPages;
            state.event.loading = false;
            state.event.page++;
            if (event) {
              if (data.items.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            state.event.loading = false;
            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }
            state.isScrollDisabled = true;
          }
        );
        // await services.eventProgress(params).then(
        //   (response) => {
        //     const { data } = response;

        //     if (event) {
        //       if (event.type == "ion-refresh") {
        //         state.event.items = [];
        //       }
        //     }

        //     data.items.some((item: object) => {
        //       state.event.items.push(item);
        //     });

        //     state.event.totalItems = data.totalItems;
        //     state.event.totalPages = data.totalPages;
        //     state.event.loading = false;
        //     state.event.page++;
        //     if (event) {
        //       if (data.items.length == 0 && loadMore) {
        //         state.isScrollDisabled = true;
        //       }
        //     }
        //   },
        //   (error) => {
        //     let message = "";
        //     try {
        //       message = error.response.data.message;
        //     } catch (e) {
        //       message = "";
        //       console.log(e);
        //     }
        //     console.log(message);
        //     state.event.loading = false;
        //     if (event) {
        //       if (event.type == "ion-refresh") {
        //         state.event.items = [];
        //       }
        //     }
        //     state.isScrollDisabled = true;
        //   }
        // );
      } else {
        await services.eventEnd(params).then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }

            data.items.some((item: object) => {
              state.event.items.push(item);
            });

            state.event.totalItems = data.totalItems;
            state.event.totalPages = data.totalPages;
            state.event.loading = false;
            state.event.page++;
            if (event) {
              if (data.items.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            state.event.loading = false;
            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }
            state.isScrollDisabled = true;
          }
        );
      }
      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const onEventIdLink = (id: number) => {
      router.push({
        name: "EventId",
        params: { id: id },
      });
    };

    const onTab = (tab: string) => {
      if (tab != state.activetab) {
        if (tab == "progress") {
          analytics.setScreenName(screenName);
        } else {
          analytics.setScreenName(screenNameClose);
        }

        state.activetab = tab;

        state.event.items = [];
        state.event.loading = true;
        state.event.page = 1;
        state.event.size = 20;
        state.isScrollDisabled = false;

        fetch();
      }
    };

    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.event.page = 1;
      state.event.loading = true;
      state.isScrollDisabled = false;
      fetch(event);
    };

    // onMounted
    onMounted(() => {
      state.event.items = [];
      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
      analytics.setScreenName(screenNameClose);
    });

    return { state, onEventIdLink, onClose, onTab, onRefresh, onLoadData };
  },
});
